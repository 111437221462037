/* .popup-outer {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: relative;
}
.popup-inner {
  position: fixed;
  width: 50%;
  height: 50%;
} */

.popup-outer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 999;
}

.popup-inner {
  background: #fff;
  padding: 20px 40px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 500px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
  /* Add other styles for your inner content here */
}

/* Add additional styles for your payment details and form elements */
