.requestcard_updateaction_form{
    width: 100% !important;
}

@media(min-width:300px) and (max-width: 768px){
  .requestcard_updateaction_form{
    margin-left: 0px !important;
    margin-right: 3px !important;
}
}

.userUpdate_action_form{
    width: 100% !important;
}


  .requestcardfilterbuttonWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
  }


  @media(max-width:600px){
    .requestcardfilterbuttonWrapper{
      /* display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: center; */
      z-index: 9999;
    }
    
  }
.requestcardfilterbuttonstyle{
  background: linear-gradient(90deg,#01c3ccdb,#2a76e8f2);
  border: none;
  border-radius: 25px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  /* margin-top: 10px; */
  margin:0px 5px;
  padding: 10px 30px;
  z-index: 99;
}

/* @media(max-width:600px){
  .requestcardfilterbuttonstyle{
  width: 100% !important;
  }
} */

/* @media(max-width:600px){
  .data-table-extensions-filter{
    width: 100% !important;
  }
} */



.filter-popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.filter-popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.ModalPopupContainer{
  display: flex;
justify-content: center;
  align-items: center;
  text-align: center;
}

.ModalPopupWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  outline: none;
}

#ModalSelectfield{
  width: 100% !important;
}

.ModalSelectfield{
  width: 100%;
}



.sticky-header-container{
  margin-top: -67px !important;
}
@media(max-width:600px){
  .sticky-header-container{
    margin-top: -67px !important;
  }
}

/* @media(max-width:900px){
  .sticky-header-container{
    margin-top: 0px !important;
  }
} */

.FilterIconTextWrapper{
  visibility: hidden;
}

@media(max-width:600px){
  .FilterIconTextWrapper{
   visibility: visible;
     font-size: 18px;
    cursor: pointer;
    /* background: gray; */
     padding: 10px 0 10px 0px;
     /* border-radius: 50px; */
     color: white;
     z-index: 999;
  }
}

@media(max-width:600px){
  .FilterIcon{
   display: flex;
   color: gray;
   font-size: 12px;
   justify-content: center;
   align-items: center;
  }
}
@media(max-width:600px){
  .filtertext{
    padding: 0 5px;
  }
}
@media(max-width:370px){
  .filtertext{
    padding: 0 2px;
  }
}

@media(max-width:600px){
  #FilterIconText{
    display: none;
  }
}


.Contact_filter_fieldbutton_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: flex-start;
  
}

.Contact_filter_field_wrapper{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* text-align: center; */
  width: 100%;
}

.Contact_filter_Inputfield{
  margin: 0 10px;
  position: relative;
  width: max-content !important;
}
.contact_filter_buttonstyle{
  border: none !important;
  background: #2196F3 !important;
  padding: 15px 30px !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
}

.contact_filter_buttonwrapper{
  margin-top: 20px !important;

}

.contact_filter_buttonwrapper :hover{
  background: #545EDF !important;
}

.contact_filter_toDate_calander_icon{

  position: absolute;
  top: 10px;
  right: 27px;
  font-size: 25px;
  cursor: pointer;
  color: #bebebe;
}

.dateiconmain{
  position: relative;
  /* width: 245px; */
}



.Contact_filterandsearch_wrapper{
  display: flex !important;
  justify-content: center;
}

.react-datepicker-wrapper{
  width: 100% !important;
}

.ChangesEditform{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}


@media(max-width:600px){
  .EditStatus_form{
    margin-right: 10px !important;
  }
}


.UserEditForme{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}



/* .data-table-extensions{
  margin-top: -55px;
} */



#exampleModalLabelContainer{
  margin-right: 15px !important;
}


 
.bylRxZ{
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
 
}

.ilZqYz {
  /* overflow: visible !important; */
}