/* .Total_Titlebox_container{
   margin-bottom: 15px !important;
} */

@media (max-width: 768px) {
  .Total_Titlebox_container {
    margin-bottom: 15px !important;
  }
}

.QRcode_wrapper {
  padding: 0 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 1320px) {
  #grettingWrapper {
    width: 100%;
  }
}

@media (max-width: 1320px) {
  .Greeting_total_wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1320px) {
  #total_wrapper_container {
    width: 100% !important;
  }
}

.Dashboard_appointment_visitors_wrapper {
  display: flex;
}

@media (max-width: 1024px) {
  .Dashboard_appointment_visitors_wrapper {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1320px) {
  .Appointment_wrapper {
    padding: 0 10px 0 0;
  }
}

@media (min-width: 1320px) {
  .Visitors_wrapper {
    padding: 0 0 0 10px;
  }
}

@media (max-width: 1320px) {
  .Appointment_wrapper {
    padding: 0 10px;
  }
}

@media (max-width: 1024px) {
  .Appointment_wrapper {
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .Visitors_wrapper {
    width: 100%;
    padding: 0;
  }
}

.physicalcard_recentbusiness_wrapper {
  display: flex;
  width: 100%;
}

.recentbusines_card_wrapper {
  width: 50% !important;
}

@media (max-width: 1250px) {
  .recentbusines_card_wrapper {
    width: 100% !important;
    padding: 0 0 0 0 !important;
  }
}
/* @media(min-width:1440px){
    .physical_card_wrapper{
        width: 40%;
    }
} */
@media (max-width: 1440px) {
  .physical_card_wrapper {
    width: 30%;
    /* width: 490px; */
  }
}
.physical_card_wrapper {
  width: 590px;
}
@media (max-width: 1550px) {
  .physical_card_wrapper {
    width: 50%;
  }
}

@media (max-width: 1250px) {
  .physical_card_wrapper {
    width: 60%;
    padding: 0 0 0 0px !important;
  }
}
@media (max-width: 1024px) {
  .physical_card_wrapper {
    width: 90%;
  }
}

@media (max-width: 1440px) {
  .physical_card_wrapperAdmin {
    width: 30%;
    /* width: 490px; */
  }
}
.physical_card_wrapperAdmin {
  width: 590px;
}
@media (max-width: 1550px) {
  .physical_card_wrapperAdmin {
    width: 50%;
  }
}

@media (max-width: 1250px) {
  .physical_card_wrapperAdmin {
    width: 60%;
    padding: 0 0 0 0px !important;
  }
}
@media (max-width: 1024px) {
  .physical_card_wrapperAdmin {
    width: 100%;
  }
}


@media (max-width: 768px) {
  .physical_card_wrapper {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .physical_card_wrapperAdmin {
    width: 100%;
  }
}

@media (max-width: 1250px) {
  .physicalcard_recentbusiness_wrapper {
    display: flex;
    flex-direction: column;
  }
}

.physical_card_wrapper {
  padding: 0 0 0 10px;
}

.physical_card_wrapperAdmin {
  padding: 0 0 0 10px;
}
.recentbusines_card_wrapper {
  padding: 0 10px 0 0;
}





/* ///////////////////////////////////////        plan responsive css        /////////////////////////////*/



@media(max-width: 1250px){
  .plan_Wrapper{
     width: 50% !important;
  }
}
@media(max-width: 650px){
  .plan_Wrapper{
     width: 100% !important;
  }
}

@media(max-width:1440px){
  #planwrapper_height{
    height: max-content !important;
  }
}

#exampleModalLabelContainer{
  width: 100% !important;
}