@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

/* templat css */
/* #temp7 .main-temaplt{  font-family: 'lato', sans-serif !important;} */
#temp7 .wtemplate {
    background: #171717;
}

#temp7 ul.gallerylist li {
    margin: 4px 4px;
    width: 28%;
    border-radius: 18px;
}

#temp7 ul.gallerylist li img {
    border-radius: 16px;
}

#temp7 h3.sectitle {
    font-size: 22px;
    margin-bottom: 15px;
    color: #fff;
    font-weight: 600;
    text-align: start;
}

#temp7 .servesbox {
    padding: 0;
    margin: 30px 6px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    list-style-type: none;
}

#temp7 p.para {
    font-size: 15px;
    font-weight: 500;
    color: #B5B5B5;
    text-align: start;
}

#temp7 .sec {
    /* background: #f1f1ee; */
    border-radius: 14px;
    /* padding: 30px 0; */
    padding: 10px 0;
    margin-bottom: 18px;
    margin: 23px 23px;
}

#temp7 .servicesName.sec {
    /* padding: 30px 30px 0; */
}

#temp7 article.abouttemplate.sec {
    text-align: center;
}

#temp7 h3.username {
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    /* padding-left: 55px; */
}

#temp7 input.form-control {
    border-radius: 55px;
    background-color: #212121;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    color: white;
    padding: 15px 25px;
}

#temp7 input.form-control::placeholder {
    color: #505050 !important;
}

#temp7 textarea.form-control::placeholder {
    color: #505050 !important;
}

#temp7 textarea.form-control {
    border-radius: 15px;
    background-color: #212121;
    border: 0.5px solid rgba(255, 255, 255, 0.33);

}

#temp7 select.form-select {
    border-radius: 11px;
    background-color: #212121;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    border-radius: 50px;
    color: #fff;
    padding: 15px 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Add custom arrow */
    background-image: url(../../../../Images/d-arrow-w.png);
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 17px;

}

#temp7 button.btn.btn-submitform {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgb(0 0 0), rgb(0 0 0)); */
    background: #1BBFA2;
    width: 100%;
    color: #fff;
    border-radius: 50px;
    padding: 15px 0;
    font-size: 18px;
}

#temp7 ul.socil-link li {
    width: 60px;
    height: 60px;
    display: flex;
    text-align: center;
    line-height: 40px;
    font-size: 45px;
    border-radius: 9px;
    /* background: #FFFCFC; */
    /* box-shadow: 0px 4px 10px 0px rgba(179, 156, 156, 0.25); */
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

#temp7 article.imgsechere.sec {
    position: relative;
    width: 100%;
    height: 280px;
    overflow: hidden;
    border-radius: 0;
}

#temp7 article.personalinfo {
    /* text-align: center; */
    text-align: start;

}

#temp7 article.qrcode-open {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp7 .logo-profile {
    position: absolute;
    top: 230px;
    left: 20px;
    right: 0px;
    z-index: 2;
    border: 1px solid #1BBFA2;
}

#temp7 article.personalinfo {
    margin-top: 0px;
    position: absolute;
    left: 160px;
    /* top: 270px; */
    top: 300px;
    /* margin-top: 60px; */

}

#temp7 button.btn.btnmakeappitmnets {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background-color: #1BBFA2;
    width: 100%;
    color: #fff;
    border-radius: 50px;
    padding: 15px 0;
    font-size: 18px;

}

#temp7 .timelist {
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
}



#temp7 ul.timelist li .day {
    /* margin-right: 28px; */
    font-size: 15px;
    font-weight: 500;
    /* width: 27%; */
    padding: 0 10px;
    color: #fff;
}

#temp7 ul.servesbox li {
    position: relative;
}

#temp7 ul.conlink li i {
    min-width: 40px;
    height: 40px;
    /* background: antiquewhite; */
    text-align: center;
    line-height: 40px;
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%); */
    color: #fff;
    border-radius: 5px;
}

#temp7 ul.conlink li span {
    padding-left: 10px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    color: #fff;
    text-align: left;
}

#temp7 ul.conlink li {
    /* margin-bottom: 19px; */
    /* display: flex; */
}

#temp7 ul.conlink {
    padding: 0px;
}

#temp7 ul.conlink li a {
    display: flex;
    align-items: center;
}

#temp7 p.titleuser {
    color: #8f8d8d;
    margin: 0;
    font-size: 14px;
    /* padding-left: 70px; */
}

#temp7 article.businesshour.sec h3 {
    text-align: center;
}

#temp7 ul.servesbox li {
    position: relative;
}

#temp7 ul.servesbox li:before {
    content: "";
    width: 15px;
    height: 15px;
    /* background: #ff752a; */
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    left: -9px;
    top: 0px;
}

#temp7 ul.servesbox li {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    border-radius: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* border-left: 2px solid #ff5c23; */
    /* line-height: 15px; */
    background-color: #1BBFA2;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
    padding: 13px 10px;
}

#temp7 ul.timelist li {
    position: relative;
}

#temp7 ul.timelist li:before {
    content: "\f017";
    font-family: 'FontAwesome';
    color: #6A6A6A;
    /* margin-right: 11px; */
    position: absolute;
    right: 20px;
    font-size: 24px;
}

#temp7 .time {
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
}


#temp7 .testimoniyalbox.sec {
    /* background: #f8f8f8; */
    background: transparent;
    color: #000;
}

#temp7 .testimonialimg {
    border: 5px solid #ffc708;
}

#temp7 .testimonialcontent {
    display: flex;
    margin: 10px 0px;
    align-items: flex-start;
}

#temp7 .testimonialcontent .testiprofile {
    display: flex;
    margin: 25px;
    align-items: center;
}

#temp7 .testiprofile {
    width: 170px;
    height: 170px;
}

#temp7 .testimonialname {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

#temp7 .testimonialdescription {
    font-size: 15px;
    text-align: justify;
    /* line-height: 22px; */
    padding-top: 5px;
    font-size: 12px;
    color: white;
    /* width: 70%; */
}

#temp7 .testibox {
    text-align: center;
    width: 480px;
}

#temp7 .testimonials-container {
    overflow: hidden;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out 0s;
}

#temp7 .testimonials {
    display: flex;
    width: max-content;
    /* width: 200% !important; */

}

#temp7 .testiimg {
    max-width: 90px;
    max-height: 90px;
}

#temp7 .testimonialcontent img {
    /* border: 3px solid #ffc708 !important; */
    margin: 0 !important;
    border-radius: 50px;
}

#temp7 .sectitle.testimonial {
    /* color: #000; */
    color: #fff;
}

#temp7 .testibox {
    text-align: justify;
    position: relative;
}

#temp7 label.form-label {
    color: #FF9635 !important;
    font-size: 14px;
}

#temp7 .clintname {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
}


/* comman css */

#temp7 .testibox {
    text-align: center;
}



#temp7 h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0px;
}

#temp7 .main-temaplt ul {
    list-style-type: none;
    padding: 0px;
}

#temp7 ul {
    margin: 0px;
}

#temp7 .main-temaplt a {
    text-decoration: none;
    color: #fff;
}

#temp7 .logo-profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 !important;
}

#theme3 .clint-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 12px 0px;
}

ul.startaring {
    margin-top: 16px;
}

img.mx-auto.d-block.img-fluid.coverimghere {
    width: 100vw;
    height: 285px;
}


.wtemplate {
    width: 520px;
    position: relative;
    padding: 0px;
}

#temp7 ul.socil-link {
    display: flex;
    /* justify-content: space-around; */
    justify-content: start;
    flex-wrap: wrap;
    padding: 0px;
    margin-top: 30px;
    gap: 10px;
}

#temp7 ul.socil-link-inner {
    display: flex;
    justify-content: start;
    padding: 0px;
    margin-top: 30px;
    gap: 0px;
    color: white;
}

#temp7 ul.socil-link-inner li a {
    /* color: transparent; */

}

#temp7 input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    color: white !important;
    display: none;
    /* background-color: #fff; */
}

#temp7 ul.timelist li {
    display: flex;
    margin-bottom: 14px;
    align-items: center;
    border-radius: 55px;
    border: 0.5px solid rgba(255, 255, 255, 0.33);
    background: #212121;
    backdrop-filter: blur(40px);
    padding: 20px 10px;
}

#temp7 ul.gallerylist {
    display: flex;
    flex-wrap: wrap;
}

#temp7 ul.gallerylist li {
    margin: 4px 4px;
}

#temp7 article.fixedfooter {
    display: flex;
    justify-content: space-around;
    /* padding: 0 20px;
    align-items: center;
    background: transparent; */
    margin: 0px;
}

@media(max-width: 380px){
    #temp7 article.fixedfooter{
        justify-content: space-between;
    }
}

#temp7 .personalinfo.modal-info {
    text-align: center;
}

#temp7 .fixedd {
    position: fixed;
    bottom: 0px;
    width: inherit;
    background: #1BBFA2;
    transform: translate(0%, 0px);
    padding: 0;
    box-shadow: 0px 0px 18px 5px #0000000d;
    z-index: 999999;
}

#temp7 .addtocinatct {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 13px; */
    background: transparent;
    color: #fff;
    padding: 10px 15px;
    border-radius: 23px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#temp7 ul.startaring {
    display: flex;
    justify-content: center;
    color: #FFC107;
    font-size: 10px;
}

#temp7 .footerleft {
    position: absolute;
    display: flex;
    top: -30px;
    left: -30px;
    width: 100px;
    height: 100px;
    background: white;
    border: 11px solid #1BBFA2;
    text-align: center;
    line-height: 35px;
    color: #1BBFA2;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    right: 0;
    margin: 0px auto;
    /* box-shadow: 0px 0px 9px 2px #000; */
}



#temp7 .qrcodeshaere {
    cursor: pointer;
    cursor: pointer;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    border: 5px solid #fff;
    border-radius: 100px;
    padding: 45px;
}

#temp7 .addtocinatct {
    cursor: pointer;
}

@media (max-width: 480px) {
    .fixedd {
        width: 100vw;
    }

    .coverimghere {
        width: 100% !IMPORTANT;
    }

    ul.gallerylist li {
        margin: 4px 4px;
        width: 46%;
    }

    .wtemplate {
        padding: 0px;
    }

    #temp7 .sec {
        /* background: #F8F8F8; */
        border-radius: 14px;
        /* padding: 20px; */
        margin-bottom: 18px;
        margin: 21px 9px;
    }



}

#temp7 .modal-content {
    width: 80%;
    border-radius: 20px;
}

#temp7 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp7 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp7 .personalinfo.modal-info p {
    font-size: 16px;
}

#temp7 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp7 .image-carousel {
    position: relative;
    width: 60%;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
}

#temp7 .owl-carousel .owl-nav button.owl-prev {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-left: 85%;
}

#temp7 .owl-carousel .owl-nav button.owl-next {
    background-color: #ffc708 !important;
    margin: 27px;
    margin-right: 10%;
}

#temp7 .owl-carousel button.owl-dot {
    background-color: #ffc708 !important;
}

#temp7 .carousel-btn {
    /* background: linear-gradient(99.5deg, #FF441B -20.5%, #FF9635 112.82%), linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)); */
    background: #1BBFA2;
    color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    margin: 0 10px 0 0px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
}

#temp7 .carousel-container {
    display: flex;
    justify-content: flex-start;
    padding-left: 110px;
}

#temp7 .personalinfo canvas {
    width: 260px !important;
    height: 260px !important;
}

#temp7 .personalinfo.modal-info h3 {
    padding-top: 10px;
    font-size: 22px;
}

#temp7 .personalinfo.modal-info {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

#temp7 .twoimages {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

#temp7 .skeleton-blog {
    margin-bottom: 2em;
    animation: scale 4s infinite;
}

#temp7 .skeleton-footer {
    height: 60px;
    width: 100%;
    border-radius: 6px;
    background: #E5E4E2;
    margin-top: 10px;
}

@media (max-width:400px) {
    #temp5 .footerleft {
        width: 80px;
        height: 80px;
        border: 4px solid #24292ce0;
    }

    #temp5 .qrcodeshaere {
        font-size: 40px;
    }
}

@media (max-width:520px) {
    #temp7 .fixedd {
        position: fixed;
        bottom: 0px;
        width: 100vw;
        /* background: #fcfbfb; */
        transform: translate(0%, 0px);
        padding: 0;
        box-shadow: 0px 0px 18px 5px #0000000d;
        z-index: 999999;
    }
}

#temp7 .qr-logo-image {
    /* style={{
      width: "80px",
      height: "80px",
      position: "absolute",
      top: "150px",
      left: "150px",
      borderRadius: "50%",
    }} */
    width: 80px;
    height: 80px;
    position: absolute;
    top: 150px;
    left: 0px;
    right: 0px;
    border-radius: 50%;
    margin: 0 auto;
}

#temp7 canvas {
    border-radius: 20px;
}

#temp7 .canvas-container {
    display: flex;
    justify-content: center;
}

.logoTextContainer {
    /* position: absolute;  */
    /* display: flex; */
    padding: 20px 0;
    margin: 30px;

}

#temp7 .calanderIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
}

#temp7 .extmakeappointmentform{
    background-color: #fff !important;
    box-shadow:rgba(0, 0, 0, 0.15) 0px 4px 10px;
    border-radius: 20px !important;
    color: #1c1a1a !important;
}

#temp7 .extmakeappointmentsubmitbtn{
    background: #1BBFA2 !important;
    width: 100% !important;
    color: #fff !important;
    border-radius: 20px !important;
    padding: 12px 0 !important;
    font-size: 18px !important;
    border: none !important;
  }

  #temp7 .extmakeappointmentformplaceholder input::placeholder {
    color: #a4a4a4 !important;
}

/* input[type="date"] {
    -webkit-appearance: none !important;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../../../Images/calendarIcondays.png');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 20px;
    color: white !important;
    position: absolute;
    top: 20px;
  }

  input[type="date"]:focus {
    outline: none; 
    border-color: blue; 
  } */