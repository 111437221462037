@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap");

/* templat css */
#temp9 .main-temaplt {
  font-family: "Arimo", sans-serif !important;
}

#temp9 .wtemplate {
  /* background: #f7fdff; */
  background: #010101;
}

#temp9 .circle {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  background: radial-gradient(circle at top, #da5afa 0%, #3570ec 60%);
  /* background: radial-gradient(100% 0 at 100% 0, #DA5AFA 0%, #3570EC 60%); */
  opacity: 60%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  right: -145px;
  top: -175px;
  transform: rotate(250deg);
}

#temp9 .circle2 {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: radial-gradient(circle at top, #da5afa 0%, #3570ec 60%);
  /* background: radial-gradient(100% 0 at 100% 0, #DA5AFA 0%, #3570EC 60%); */
  opacity: 60%;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
  position: absolute;
  left: -130px;
  top: 208px;
  transform: rotate(250deg);
}

#temp9 article.personalinfo.sec {
  /* background: linear-gradient(89.08deg, #0165FC -3.38%, #122B65 175.37%); */
  /* background-color: #262B2E; */
  padding-top: 130px;
  padding-bottom: 75px;
}

#temp9 .personalinfo.sec:before {
  content: '';
  position: absolute;
  background: #e5316e;
  width: 130%;
  height: 10px;
  transform: rotate(-14deg);
  bottom: 80px;
  z-index: -1;
  left: -20px;
}

#temp9 h3.sectitle {
  font-family: 'Jost';
  font-size: 18px;
  font-weight: 600;
  margin: 10px 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

#temp9 .contactinfotext {
  line-height: 20px;
}

#temp9 p.aboutus_paragraph {
  text-align: center;
  width: 100%;
  padding: 5px 0;
  color: #676d75;
  font-size: 13px;
  font-family: "Open Sans";
}

h1 #temp9 img.mx-auto.d-block.img-fluid.logo-profile {
  position: absolute;
  top: 30px;
  left: 9px;
  border: -26px;
}

#temp9 article.imgsechere.sec:before {
  background: #00000070;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#temp9 article.personalinfo.sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: row;
  /* background: #333333b0; */
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

#temp9 .top-section:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 485px solid transparent;
  border-bottom: 130px solid #000;
  z-index: 9;
}

#temp9 .ext-top:before {
  border-left: 525px solid transparent !important;
  border-bottom: 130px solid #000 !important;
  z-index: 9;
}

#temp9 .innerinfo {
  text-align: left;
  margin: 15px 0;
  /* margin-bottom: 20px; */
  /* margin-left: 44px; */
}

#temp9 .social_media_li {
  margin: 0;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  /* background: linear-gradient(99.5deg, #00A7D6 -20.5%, #00D2C9 112.82%); */
}

#temp9 h3.username {
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 600;
  line-height: 23px;
  color: #fff;
  font-family: 'Jost';
  text-transform: uppercase;
  margin-bottom: 9px;
}

#temp9 button.btn.btnmakeappitmnets {
  padding: 13px 15px;
  border-radius: 8px;
  background: #E5316E;
  background-color: #E5316E;
  width: 100%;
  color: #fff;
  font-size: 14px;
  border: none;
}

.businessHours_sec {
  margin: 0 auto;
  padding: 10px 15px;
  width: 90%;
  border-radius: 10px;
  background: transparent;
  box-shadow: none;
}

#temp9 button.btn.btn-submitform {
  padding: 17px 15px;
  border-radius: 10px;
  background-color: #E5316E;
  width: 100%;
  font-size: 14px;
  border: none;
  color: #fff !important;
  font-family: 'Jost';
  text-transform: uppercase;
}

#temp9 p.titleuser {
  color: #E5316E;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

#temp9 ul.socil-link li a {
  color: #fff;
  font-size: 32px;
  padding: 0;

}

#temp9 .gradian-icon {
  font-weight: 800;
  background: -webkit-linear-gradient(#9365ff, #529bff);
  /* background: -webkit-linear-gradient(#1D94F0, #67D8F9); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.infomain {
  color: #fff !important;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  padding-bottom: 15px;
  justify-content: center;
}

#temp9 .infomain {
  justify-content: start;
  align-items: center;
  padding: 8px 2px;
}

.info_icons {
  margin-right: 0.5rem;
  background: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



#temp9 ul.conlink li {
  text-align: center;
  background: #E5316E21;
  margin: 10px 0;
}

#temp9 .contact-info-container {
  text-align: center;
  background: #E5316E21;
  margin: 5px 0;
  width: 100%;
}

#temp9 .icon-bg i.fa {
  color: #dc3545 !important;
  background: #dc3545;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 0;
}

#temp9 ul.conlink {
  padding: 0 !important;
}


#temp9 .info_icons {
  color: #dc3545 !important;
  background: #dc3545;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 0;
}

#temp9 .icon-bg {
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  min-width: 35px;
  width: 35px;
  height: 35px;
  align-items: center;
  margin-right: 0.5rem;
}

#temp9 article.businesshour.sec {
  /* background: #0165fc; */
  color: #fff;
}

#temp9 ul.socil-link li {
  background-color: #5D1C32;
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#temp9 article.contact.sec {
  /* background-color: #2f3438; */
  margin: 5px 0px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp9 ul.servesbox li {
  text-transform: uppercase;
  margin: 5px;
  background: linear-gradient(270deg, rgba(229, 49, 110, 0) 0%, rgba(229, 49, 110, 0.36) 23.32%, rgba(229, 49, 110, 0.36) 79.61%, rgba(229, 49, 110, 0) 101.41%);
  color: #fff;
  padding: 5px 15px;
  font-size: 12px;
  border: none;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 6px;
  padding: 12px 15px;
  font-size: 15px;
  margin: 3px 5px;
  text-align: center;
  font-weight: 600;
  height: 53px;
}

#temp9 ul.servesbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

#temp9 article.abouttemplate.sec {
  /* margin-top: 70px; */
  /* background-color: #2f3438; */
  margin: 10px 10px;
  position: relative;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp9 .social-links-sec {
  /* background-color: #2f3438; */
  margin: 5px 10px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp9 .social-name {
  color: #fff;
}

#temp9 .sec {
  padding: 15px 15px;
  z-index: 1;
  position: relative;
}

#temp9 .sec .appointment {
  border: 1px solid #562435;
  padding: 15px 15px;
  background: linear-gradient(0deg, #201619, #201619),
    linear-gradient(0deg, #562435, #562435);
  border-radius: 5px;

}

#temp9 .services {
  border: 1px solid #562435;
  padding: 15px 15px;
  background: linear-gradient(0deg, #201619, #201619),
    linear-gradient(0deg, #562435, #562435);
  border-radius: 5px;


}

#temp9 .contactform-sec-inner {
  border: 1px solid #562435;
  padding: 15px 15px;
  background: linear-gradient(0deg, #201619, #201619),
    linear-gradient(0deg, #562435, #562435);
  border-radius: 5px;


}

#temp9 .testimoniyalbox .testimonial-inner {
  border: 1px solid #562435;
  padding: 15px 15px;
  background: linear-gradient(0deg, #201619, #201619),
    linear-gradient(0deg, #562435, #562435);
  border-radius: 5px;


}

#temp9 .contact .contact-inner {
  border: 1px solid #562435;
  padding: 15px 15px;
  background: linear-gradient(0deg, #201619, #201619),
    linear-gradient(0deg, #562435, #562435);
  border-radius: 5px;


}

#temp9 .sec .gallery-inner {
  border: 1px solid #562435;
  padding: 15px 15px;
  background: linear-gradient(0deg, #201619, #201619),
    linear-gradient(0deg, #562435, #562435);
  border-radius: 5px;
}

#temp9 .sec .gallery-inner .slick-slide {
  padding: 0 3px;
}
#temp9 .sec .gallery-inner .slick-slide div {
  height: 180px;
}
#temp9 .sec .gallery-inner .slick-slide div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
} 

#temp9 .testimonials-container .slick-list{
  padding-top: 0 !important;
}
#temp9 .rating {
  color: #ffa438;
}

#temp9 .testimoniyalbox.sec {
  /* background-color: #2f3438; */
  margin: 5px 0px;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3);
}

#temp9 .testimonialimg {
  border: 5px solid #ffc708;
}

#temp9 .testimonialcontent {
  display: flex;
  /* margin: 10px 0; */
  align-items: flex-start;
}

#temp9 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp9 .testiprofile {
  width: 170px;
  height: 170px;
}

#temp9 .testimonialname {
  font-size: 20px;
}

#temp9 .testimonialdescription {
  font-size: 15px;
  text-align: left;
  line-height: 22px;
  padding-top: 5px;
}

#temp9 .testibox {
  text-align: center;
  width: 100%;
  max-width: 500px;
}

#temp9 .testimonials-container {
  overflow: hidden;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
  background: linear-gradient(270deg, rgba(229, 49, 110, 0) 0%, rgba(229, 49, 110, 0.36) 23.32%, rgba(229, 49, 110, 0.36) 79.61%, rgba(229, 49, 110, 0) 101.41%);
  margin: 0 -10px;
  padding: 0 10px;
}

#temp9 .testimonials {
  display: flex;
  width: max-content;
}

#temp9 .testiimg {
  max-width: 90px;
  max-height: 90px;
  width: 100%;
  /* border: 1px solid #ccc;
  border-radius: 50%; */
  overflow: hidden;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 5px;
}

#temp9 .clintname {
  padding: 15px;
  min-width: 90px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

#temp9 .space-bottom {
  margin-top: 75px;
}

#temp9 .testimonialcontent img {
  /* border: 3px solid #ffc708 !important; */
  margin: 0 !important;
}

#temp9 .sectitle.testimonial {
  color: #ffffff;
}

#temp9 ul.timelist li .day {
  /* margin-right: 19px; */
  font-size: 15px;
}

#temp9 ul.timelist li .time {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #1fdece;
}

#temp9 label.form-label {
  display: none;
}

/* comman css */
#temp9 .clear_string {
  color: #fff;
}

.clor-blk {
  color: black;
}

#temp9 .form-control.clear_string.popup::placeholder {
  color: #fff;
}

#temp9 .form-control.clear_string.popup {
  color: #fff !important;
}

#temp9 input.form-control.clear_string::-webkit-calendar-picker-indicator {
  background-image: url(../../../../Images/calendar-b.png);
}

#temp9 .form-select {
  background-color: #fff !important;
  color: #010101;
  padding: 15px 7.5px;
  background-color: #383f46;
  border-radius: 5px;
}

#temp9 .form-select option {
  color: #000;
}

#temp9 .testibox {
  text-align: center;
}

#temp9 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

#temp9 .main-temaplt ul {
  list-style-type: none;
  padding: 0px;
}

#temp9 .businesshour ul {
  list-style-type: none;
  /* padding: 0px; */
  width: 100%;
  margin: 0px;
  padding: 10px 15px !important;
  border: 1px solid #ccc;
  border-image-source: linear-gradient(246.18deg, rgba(255, 255, 255, 0.3) 68.96%, rgba(45, 45, 45, 0) 118.89%);
  border-image-slice: 1;
}

#temp9 ul {
  margin: 0px;
  padding: 0 10px;
}

#temp9 .businessHours_sec ul {
  margin: 0px;
  padding: 10px 15px !important;
  border: 1px solid #ccc;
  border-image-source: linear-gradient(246.18deg, rgba(255, 255, 255, 0.3) 68.96%, rgba(45, 45, 45, 0) 118.89%);
  border-image-slice: 1;
}

#temp9 .main-temaplt a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: white;
  font-family: 'Jost';
}

#temp9 .logo-profile {
  /* background: linear-gradient(#d68b64, #e6497e); */
  padding: 2px;
  max-width: 100%;
  /* height: 140px; */
  width: 120px;
  border-radius: 50%;
}

#temp9 .logo-parent {
  background: #000;
  padding: 1rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 125px;
  margin-right: 20px;
}

#temp9 .logo-parent:before {
  content: '';
  width: 145px;
  height: 145px;
  position: absolute;
  /* background: #fff; */
  background: #E5316E;
  border-radius: 50%;
  z-index: -1;
}

#temp9 .clint-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 12px 00px;
}

#temp9 ul.startaring {
  margin-top: 16px;
}

#temp9 .top-section {
  position: relative;
  ;
}

#temp9 img.mx-auto.d-block.img-fluid.coverimghere {
  min-width: 100%;
  max-width: 100%;
  width: 500px;
  height: 300px;
  /* position: absolute; */
  /* filter: blur(4px); */
  /* -webkit-filter: blur(4px); */
}

#temp9 .wtemplate {
  width: 520px;
  /* position: relative; */
  padding: 0px;
  /* overflow: hidden; */
}

#temp9 .about-desc {
  font-size: 15px;
  color: #676d75;
  text-align: center;
}

#temp9 ul.socil-link {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  gap: 10px;
}

#temp9 ul.timelist li {
  display: flex;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
  color: white;
  margin-bottom: 8px;
}

#temp9 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}

#temp9 ul.gallerylist li {
  margin: 4px 4px;
  width: 46%;
}

#temp9 article.fixedfooter {
  display: flex;
  justify-content: space-between;
  padding: 8px 30px;
}

#temp9 .personalinfo.modal-info {
  text-align: center;
}

#temp9 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #181818;
  background-image: linear-gradient(270deg, rgba(229, 49, 110, 0) 0%, rgba(229, 49, 110, 0.36) 23.32%, rgba(229, 49, 110, 0.36) 79.61%, rgba(229, 49, 110, 0) 101.41%);
  transform: translate(0%, 0px);
  padding: 0px;
  box-shadow: 0px 0px 6px;
  z-index: 999999;
}

#temp9 .addtocinatct {
  background: transparent;
  color: #fff;
  padding: 10px 15px;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#temp9 ul.startaring {
  display: flex;
  justify-content: center;
  color: #ffc107;
  font-size: 10px;
}

#temp9 .footerleft {
  position: absolute;
  display: flex;
  top: -20px;
  left: 0;
  width: 75px;
  height: 75px;
  background: #ffffff;
  border: 4px solid #e5316e;
  text-align: center;
  line-height: 35px;
  color: #dc3545;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  right: 0;
  margin: 0 auto;
  /* box-shadow: 0px 0px 9px 2px #000; */
}

#temp9 .footerleft:before {
  content: '';
  width: 90px;
  height: 90px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
}

#temp9 .qrcodeshaere {
  cursor: pointer;
  font-size: 42px;
}

#temp9 .addtocinatct {
  cursor: pointer;

}

@media (max-width: 380px) {
  #temp9 .main-temaplt a {
    font-size: 14px;
  }
}

@media (max-width: 375px) {
  #temp9 .ext-top:before {
    border-left: 455px solid transparent !important;
    border-bottom: 120px solid #000 !important;
  }
}

@media (max-width: 480px) {
  #temp9 .logo-parent {
    width: 110px;
    height: 110px;
  }

  #temp9 .ext-top:before {
    border-left: 485px solid transparent !important;
    border-bottom: 125px solid #000 !important;
  }

  #temp9 .logo-parent:before {
    width: 125px;
    height: 125px;
  }

  #temp9 .footerleft:before {
    width: 70px;
    height: 70px;
  }

  .fixedd {
    width: 100vw;
  }

  #temp9 .coverimghere {
    width: 100% !important;
  }

  #temp9 ul.gallerylist li {
    margin: 4px 4px;
    width: 46%;
  }


  #temp9 .wtemplate {
    padding: 0px;
  }

  #temp9 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100%;
  }
}

#temp9 .servicesName.sec {
  /* background-color: #2f3438; */
  margin: 5px 0px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp9 .Service_name {
  padding: 15px 0px;
  /* background-color: #2f3438; */
  margin: 5px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp9 .businesshour.sec {
  /* background: linear-gradient(to bottom, #9365ff, #529bff); */
  background-color: #2f3438;
  margin: 5px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: transparent;
  box-shadow: none;
}

#temp9 .Conatctform_sec {
  padding: 15px 0px;
  /* background-color: #2f3438; */
  margin: 5px 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.3); */
}

#temp9 .contact-form {
  padding: 0 5px;
  margin: 15px 0;
}

#temp9 .contact-us-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#temp9 .contact-info-details {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding: 8px 2px;
}

#temp9 .contact-info-icon {
  color: #fff;
  font-size: 18px;
  margin-right: 0.5rem;
}

#temp9 .content {
  display: flex;
  flex-direction: column;
  text-align: left !important;
  color: #fff;
}

#temp9 .content .label {
  text-transform: uppercase;
  color: #f9a0be;
  font-size: 12px;
  font-family: 'Jost';
  font-weight: 300;
}

#temp9 .content .value {
  text-align: left;
}

#temp9 .form-control {
  background: #fff;
  padding: 15px 7.5px;
  border-radius: 4px;
  color: #010101;
  font-weight: 600;
}

#temp9 .form-control::placeholder {
  color: #B0A6AA;
  text-transform: uppercase;
  font-size: 12px;
}

#temp9 .Contact_Form_sectitle {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 25px 20px 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

#temp9 .Contact_Form_sectitle input::placeholder {
  color: #555d65;
}

#temp9 .Contact_circle {
  width: 200px;
  height: 200px;
  background-color: gray;
}


#temp9 .modal-content {
  width: 80%;
  border-radius: 20px;
}

#temp9 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp9 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
}

#temp9 .personalinfo.modal-info p {
  font-size: 16px;
}

#temp9 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp9 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp9 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}

#temp9 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}

#temp9 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}

#temp9 .carousel-btn {
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 10px 0 0px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#temp9 .carousel-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

#temp9 .personalinfo canvas {
  width: 260px !important;
  height: 260px !important;
}

#temp9 .personalinfo.modal-info h3 {
  padding-top: 10px;
  font-size: 22px;
  font-weight: 600;
  color: #181817;
  text-transform: uppercase;
}

#temp9 .personalinfo.modal-info p {
  font-size: 16px;
  color: #181817;
}


#temp9 .personalinfo.modal-info {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

#temp9 .twoimages {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#temp9 .skeleton-blog {
  margin-bottom: 2em;
  animation: scale 4s infinite;
}

#temp9 .skeleton-footer {
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background: #E5E4E2;
  margin-top: 10px;
}

@media (max-width:520px) {
  #temp9 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #181818;
    transform: translate(0%, 0px);
    padding: 0px;
    box-shadow: 0px 0px 6px;
    z-index: 999999;
  }

  #temp9 .sec {
    padding: 15px 10px;
  }
}

@media (max-width:400px) {
  #temp9 .footerleft {
    width: 60px;
    height: 60px;
    /* border: 4px solid #24292ce0; */
  }

  #temp9 .qrcodeshaere {
    font-size: 36px;
  }

  #temp9 ul.socil-link {
    gap: 10px;
  }
}

@media (max-width:480px) {
  #temp9 .testibox {
    max-width: 385px;
    width: 100%;
  }
}

#temp9 .qr-logo-image {
  /* style={{
    width: "80px",
    height: "80px",
    position: "absolute",
    top: "150px",
    left: "150px",
    borderRadius: "50%",
  }} */
  width: 80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
}

#temp9 canvas {
  border-radius: 20px;
}

#temp9 .canvas-container {
  display: flex;
  justify-content: center;
}