@import url("https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400;0,500;0,600;0,700;1,500;1,600&display=swap");
/* templat css */
#temp6 .main-temaplt {
  font-family: "Arimo", sans-serif !important;
  margin-bottom: 10px;
}
#temp6 .wtemplate {
  /* background: #f7fdff; */
}
#temp6 .aboutus-desc {
  text-align: center;
  font-size: 15px;
}
#temp6 .strip {
  width: 100%;
  position: absolute;
  top: 174px;
}
#temp6 .stripservice {
  width: 100%;
}
#temp6 article.personalinfo.sec {
  /* background: linear-gradient(89.08deg, #0165FC -3.38%, #122B65 175.37%); */
  background-color: #ffc708;
  padding: 39px 0px;
  padding-bottom: 0px;
  background-image: url("../../../../Images/theme6buildings.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 205px;
}
/* #temp6 article.testimoniyalbox.sec {
  padding: 0;
} */
#temp6 .sectitle.testimonial {
  padding: 20px;
}
#temp6 .sectitle.social {
  color: #000;
  padding-bottom: 10px;
}
#temp6 .sectitle.gallery {
  color: #000;
}
#temp6 .owl-carousel .owl-nav button.owl-prev {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-left: 85%;
}
#temp6 .owl-carousel .owl-nav button.owl-next {
  background-color: #ffc708 !important;
  margin: 27px;
  margin-right: 10%;
}
#temp6 .owl-carousel button.owl-dot {
  background-color: #ffc708 !important;
}
#temp6 .carousel-btn {
  background-color: #ffc708;
  border: 1px solid #ffc708;
  color: #2b2626;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 10px 0 0px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#temp6 .carousel-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 100px;
}
#temp6 .transition-prop {
  transition: transform 2s ease-in-out;
}
#temp6 article.businesshour.sec {
  background-color: #000 !important;
  color: #ffc708;
  background-image: url("../../../../Images/theme6buildings.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 20px 50px;
}
#temp6 h3.sectitle {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
}
#temp6 h3.sectitle.business {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-align: left !important;
}
#temp6 h1 img.mx-auto.d-block.img-fluid.logo-profile {
  position: absolute;
  top: 65px;
  left: 9px;
  border: -26px;
}

#temp6 article.personalinfo.sec {
  display: flex;
  justify-content: center;
}
#temp6 article.conatctform.sec {
  background-color: #f8f8f8;
  margin: 0;
}
#temp6 .innerinfo {
  /* margin-bottom: 20px;
    margin-left: 44px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
}

#temp6 h3.username {
  font-size: 26px;
  text-transform: uppercase;
  color: #181817;
  /* margin-bottom: 9px; */
  padding: 0;
  margin: 0;
}
#temp6 button.btn.btnmakeappitmnets {
  background: #242424;
  width: 100%;
  color: #fff !important;
  border-radius: 10px;
  padding: 18px 0;
  font-size: 15px;
  text-transform: uppercase;
}
#temp6 .makeappoint {
  color: #181817 !important;
}

#temp6 .footshadow {
  padding: 0 10px !important;
}

#temp6 button.btn.btn-submitform {
  background: #ffc708;
  width: 100%;
  color: #000000 !important;
  font-size: 20px;
  padding: 13px 25px;
  border-radius: 10px;
}

#temp6 p.titleuser {
  /* color: #fff; */
  color: #181817;
  font-size: 15px;
}
#temp6 ul.socil-link li a {
  color: #181817;
  font-size: 24px;
  width: 60px !important;
  height: 60px !important;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  }
#temp6 img.mx-auto.d-block.img-fluid.logo-profile {
  position: absolute;
  left: 0;
  border: 5px solid #ffc708;
  right: 0;
  top: 105px;
  z-index: 1;
  margin: 0 auto;
}

#temp6 .contact.sec {
  width: 100%;
  padding: 0;
}
#temp6 .conlink {
  background-color: #ffc708;
  width: 100%;
}
#temp6 ul.conlink li {
  margin-bottom: 17px;
}
#temp6 article.gallery.sec {
  background: #fff;
  color: #fff;
}
#temp6 ul.socil-link li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#temp6 article.contact.sec {
  background: #f8f8f8;
  /* margin-top: 25px; */
  /* padding: 22px 29px; */
}
#temp6 .contact-us-grid {
  align-items: center;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
}
#temp6 .contact-info-container {
  padding: 0 30px;
}
#temp6 .vertical-line {
  width: 1px;
  height: 10px; /* Set the same height as the grid items */
  background: transparent; /* Set your desired line color */
  margin: 0 10px; /* Adjust the margin as needed */
}
#temp6 .contact-info-details a  {
  text-align: center;
}
#temp6 .contactinfotext {
  font-size: 18px;
  font-weight: 500;
}
#temp6 .contact-info-label {
  font-size: 15px;
  color: #181818;
}
#temp6 .contact-info-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#temp6 .contact-info-icon {
  font-size: 18px;
  color: #fff;
  opacity: 50%;
}
#temp6 .contactinfolink {
}
#temp6 ul.servesbox li {
  /* margin: 19px; */
  /* box-shadow: 0px 1px 15px 2px #0165FC4D; */
  /* background: #0165FC; */
  /* color: #fff; */
  /* padding: 5px 15px; */
  /* border-radius: 23px; */
  font-size: 15px;
  padding-bottom: 20px;
  position: relative;
  list-style: unset;
  padding-left: 25px;
  /* padding-left: 15px; */
}
#temp6 ul.servesbox li:before {
  width: 15px;
  height: 15px;
  position: absolute; 
  left: 0;
  top: 6px; 
  content: ""; 
  border-radius: 50%; 
  background: #ffc708;
}
#temp6 ul.servesbox {
  list-style-type: none !important;
  margin: 20px 0 0;
  padding: 0;
}

#temp6 article.abouttemplate.sec {
  /* margin-top: 70px; */
  background-color: #ffc708;
  margin: 30px;
}
#temp6 .social-link-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#temp6 .social-name {
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 10px;
  color: #181818;
}
#temp6 .sectitle.desc {
  font-size: 14px;
  color: #000 !important;
  text-align: center;
  font-weight: 500;
}
#temp6 .sec {
  padding: 20px 25px;
  background-color: #ffc708;
  /* margin-top: 20px; */
}
#temp6 .testimoniyalbox.sec {
  background: #f8f8f8;
  color: #000;
  padding: 30px;
}
#temp6 .testimonialimg {
  border: 5px solid #ffc708;
}
#temp6 .testimonialcontent {
  display: flex;
    margin: 10px 0;
    align-items: flex-start;
}

#temp6 .testimonialcontent .testiprofile {
  display: flex;
  margin: 25px;
  align-items: center;
}

#temp6 .testiprofile {
  width: 170px;
  height: 170px;
}

#temp6 .testimonialname {
  font-size: 20px;
}
#temp6 ul.timelist li .day {
  /* margin-right: 19px; */
  width: 30%;
}
#temp6 ul.timelist li .time {
  /* margin-right: 19px; */
  width: 65%;
  font-weight: 600;
}
#temp6 ul.timelist li .colon {
  /* margin-right: 19px; */
  width: 5%;
}
#temp6 .testimonialdescription {
  font-size: 15px;
  text-align: justify;
  line-height: 22px;
  padding-top: 5px;
}
#temp6 #temp6 label.form-label {
  display: none;
}
/* comman css */

#temp6 .testibox {
  text-align: center;
  width: 460px;
}
#temp6 .testimonials-container {
  overflow: hidden;
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out 0s;
}
#temp6 .testimonials {
  display: flex;
  width: max-content;
}

#temp6 h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}
#temp6 .main-temaplt ul {
  list-style-type: none;
  padding: 0;
  /* padding: 0px 40px; */
}
#temp6 ul {
  margin: 0px;
}
#temp6 .main-temaplt a {
  text-decoration: none;
  color: #000;
  text-align: center;
}
#temp6 .logo-profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
#temp6 .clint-img2 {
  border-radius: 50%;
  margin: 12px 0px;
}

#temp6 .testiimg {
  max-width: 90px;
  max-height: 90px;
}

#temp6 .clintname {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 10px;
}
#temp6 .testimonialcontent img {
  border: 3px solid #ffc708 !important;
  margin: 0 !important;
}
#temp6 ul.startaring {
  /* margin-top: 16px; */
  /* margin: 0 10px; */
  margin: 0;
}
#temp6 img.mx-auto.d-block.img-fluid.coverimghere {
  width: 500px;
  height: 300px;
}

#temp6 .wtemplate {
  width: 520px;
  position: relative;
  padding: 0px;
  background-color: #fff;
}
#temp6 ul.socil-link {
  display: flex;
  justify-content: center;
  gap: 10px;
margin: 0;
  padding: 0;
  flex-wrap: wrap;
}
#temp6 .service-list {
}

#temp6 .service-list {
  position: relative;
}
#temp6 .service-list::before {
  content: "";
  position: absolute;
  min-width: 13px;
  min-height: 13px;
  border-radius: 50px;
  top: 5px;
  left: 0;
}

#temp6 article.servicesName.sec {
  background-color: #f8f8f8;
  color: #000;
  position: relative;
}
#temp6 .sectitle.service {
  color: #000;
  text-align: left;
  font-size: 20px;
}
#temp6 .sectitle.about {
  color: #000;
  font-weight: 500;
  font-size: 24px;
}
#temp6 .sectitle.testimonial {
  color: #000;
}
#temp6 .sectitle.contact {
  color: #000;

  margin: 0;
  padding: 20px;
}
#temp6 .sectitle.contactinfo {
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 20px;
}
#temp6 ul.timelist li {
  display: flex;
  margin-bottom: 10px;
  font-size: 16px;
}
#temp6 ul.timelist {
  margin: 20px 0 0;
  padding: 0;
}
#temp6 ul.gallerylist {
  display: flex;
  flex-wrap: wrap;
}
#temp6 ul.gallerylist li {
  margin: 4px 4px;
  width: 46%;
}
#temp6 article.fixedfooter {
  display: flex;
  justify-content: space-between;
  background-color: #fff !important;
  margin: 0;
  align-items: center;
}
#temp6 .personalinfo.modal-info {
  text-align: center;
}
#temp6 .fixedd {
  position: fixed;
  bottom: 0px;
  width: inherit;
  background: #fff;
  transform: translate(0%, 0px);
  padding: 10px;
  box-shadow: 0px 0px 13px 5px #00000014;
  z-index: 999999;
}

@media(max-width: 520px){
  #temp1 .fixedd {
      width: 100vw;
    }
  }
  
#temp6 .space-bottom {
  margin-top: 60px;
}

#temp6 .addtocinatct {
  background: #ffc708;
  color: #fff;
  padding: 10px 15px;
  border-radius: 23px;
  font-size: 13px;
  line-height: 24px;
}
#temp6 ul.startaring {
  display: flex;
  justify-content: center;
  color: #ffc107;
  font-size: 10px;
}

#temp6 .footerleft {
  background: #ffc708;
  text-align: center;
  line-height: 15px;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#temp6 .qrcodeshaere {
  cursor: pointer;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #303030;
}
#temp6 .addtocinatct {
  cursor: pointer;
  color: #000;
}

#temp6 .sec-common {
  margin-top: 20px;
}



/*
Image carousel
*/
#temp6 .image-carousel {
  position: relative;
  width: 60%;
  margin: auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

#temp6 button {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

#temp6 .image-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}


#temp6 .modal-content{
  width: 80%;
  border-radius: 20px;
} 
#temp6 .personalinfo.modal-info{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
#temp6 .personalinfo.modal-info h3{
  padding-top: 10px;
  font-size: 22px;
}
#temp6 .personalinfo.modal-info p {
  font-size: 16px;
}
#temp6 .personalinfo canvas{
  width: 260px !important;
  height: 260px !important;
}

#temp6 .twoimages{
  display: flex;
  flex-direction: row;
  gap: 15px;
}

#temp6 article.gallery.sec.sec-common h3.sectitle {
  padding-bottom: 20px;
  color: #303030 !important;
  font-weight: 500 !important;
}

#temp6 .skeleton-blog{
  margin-bottom: 2em;
  animation: scale 4s infinite;
 }

 #temp6 .skeleton-footer{
  height: 60px;
  width: 100%;
  border-radius: 6px;
  background: #E5E4E2;
  margin-top: 10px;
 }

 #temp6 input[type="date"]::placeholder {
  color: black; 
}

@media screen and (max-width:520px) {
  #temp6 .fixedd {
    position: fixed;
    bottom: 0px;
    width: 100vw;
    background: #fff;
    transform: translate(0%, 0px);
    padding: 10px;
    box-shadow: 0px 0px 13px 5px #00000014;
    z-index: 999999;
  }   
}

#temp6 .qr-logo-image{
  /* style={{
    width: "80px",
    height: "80px",
    position: "absolute",
    top: "150px",
    left: "150px",
    borderRadius: "50%",
  }} */
  width:80px;
  height: 80px;
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  margin: 0 auto;
  border: 4px solid #FFC708;

}
#temp6 canvas{
border-radius: 20px;
}

#temp6 .canvas-container{
  display: flex;
  justify-content: center;
}



#temp6 input[type="date"]::-webkit-calendar-picker-indicator {
  font-size: 18px;
  color: white !important;
  display: none;
  /* background-color: #fff; */
}
#temp6 .calanderIcon{
  position: absolute;
  top: 15px;
  right: 12px;
  cursor: pointer;
}


#temp6 .extmakeappointmentsubmitbtn{
  background: #FFC708 !important;
  width: 100% !important;
  color: #000 !important;
  border-radius: 20px !important;
  padding: 12px 0 !important;
  font-size: 18px !important;
  border: none !important;
}