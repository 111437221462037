.dropdown_midmenu_mobile_view {
  /* display: visible; */
}

@media (max-width: 768px) {
  #dropdown_midmenu_mobile_view {
    display: none !important;
  }
}

@media (max-width: 768px) {
  a.app-header__logo img {
    width: 45%;
    padding: 0px !important;
    opacity: 0.7;
  }
}

@media (max-width: 768px) {
  .app-header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .Header_menubarstyle {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .Header_menubarstyle {
    display: visible;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }
}

/* @media (max-width: 767px) {
  #app_sideBarStyle_wrapper {
    left: 800px !important;
  }
} */

/* @media (max-width: 767px) {
  .app #app_sidebarStyle_maincontainer {
    left: 800px !important;
  }
} */

@media (max-width: 830px) {
  .profilenmae {
    background: #fcfcfc;
    border: 1px solid #145388;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    color: #000 !important;
    cursor: pointer;
    font-weight: 700;
    margin-right: 13px;
    padding: 9px 10px !important;
    margin-right: 0px;
  }
}

@media (max-width: 830px) {
  .app-header {
    padding-right: 10px !important;
  }
}

@media (max-width: 830px) {
  .btnhead {
    padding: 9px 10px !important;
    border-radius: 4px;
    background: #fcfcfc;
    margin-right: 10px !important;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    color: #fff !important;
    background: #ffffff14;
    font-weight: bold;
    text-decoration: none;
    border: 0px;
    font-size: 13px;
    line-height: 24px;
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .RegisterButtonmainbar {
    width: auto !important;
    font-size: 14px !important;
    padding: 12px 10px !important;
  }
}

@media (max-width: 768px) {
  .LoginButtonmainbar {
    width: auto !important;
    font-size: 14px !important;
    padding: 12px 20px !important;
  }
}

@media (max-width: 450px) {
  .imagelogo {
    width: 60% !important;
  }
}

/* ///////////////////////////////// */
@media (max-width: 768px) {
  .app-sidebar {
    display: none; /* Initially hide the sidebar */
  }
}
@media (max-width: 768px) {
  .app-sidebar.visible {
    display: block; /* Show the sidebar when the 'visible' class is present */
  }
}

@media (max-width: 450px) {
  #MobileBaarview {
    min-width: 180px;
  }
}

/* /////////////////////////////////////////// header js /////////////////// */

.all-notifications-container {
  background-color: #f4f5f7;
  width: 415px;
  max-height: 300px;
  overflow: scroll;
  position: absolute;
  top: 64px !important;
  border-radius: 10px;
  border: 1px solid #cecdd5;
}

@media (max-width: 768px) {
  .all-notifications-container {
    right: 5px !important;
    width: 330px !important;
  }
}

@media (max-width: 650px) {
  .all-notifications-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    width: 360px;
  }
}

@media (min-width: 200px) and (max-width: 450px) {
  .HeaderCreateBusinessClass {
    margin: 0 !important;
    padding: 10px 6px !important;
    font-size: 12px !important;
  }
}

@media (min-width: 200px) and (max-width: 450px) {
  .HeaderlogoClass {
    padding: 0 !important;
  }
}

.notificationbell {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
@media (min-width: 200px) and (max-width: 450px) {
  .notificationbell {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px !important;
    cursor: pointer;
  }
}

@media (min-width: 768px) {
  .MobilesidebarcloseButton {
    display: none;
  }
}

@media (max-width: 768px) {
  .MobilesidebarcloseButton {
    display: visible !important;
    padding: 20px 20px 18px 20px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    justify-content: flex-end !important;
    font-size: 18px !important;
  }
}
