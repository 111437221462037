
.usefullink_listyle li a , .usefullink_listyle {
    text-decoration: none;
    font-size: 14px !important;
}

@media (max-width:768px) {
    .poweredbytextold{
        cursor: pointer;
        position: absolute;
        right: 0;
        left: 0;
        width: 100vw;
        text-align: center;
        top: 40px;
        color: #fff;
    }
}
