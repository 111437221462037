
body{font-family: 'Montserrat', sans-serif;
}


.btns_main a {
    font-size: 17px;
}
.f-link li:last-child{ padding-bottom: 0px;}
.main-footer {
    background: #053f72;
    /* padding: 40px 0px; */
}
.f-link h3 {
    color: #fff;
    font-size: 20px;
    margin-bottom: 28px;
    position: relative;
}

.f-link h3:before {
    content: "";
    width: 53px;
    background: #ffffff;
    height: 2px;
    display: block;
    position: absolute;
    bottom: -10px;
}

.f-link li {
    padding: 8px 0px;
    color: #fff;
}
ul.socilmedia {
    display: flex;
    justify-content: flex-end;
}
ul.socilmedia li {
    margin: 0px 22px;
}

ul.socilmedia i {
    border: 1px solid #fff;
    /* padding: 2px 13px; */
    border-radius: 4px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
}
.topfooter .container {
    border-bottom: 1px solid #ffffff40;
    padding: 25px 0px;
}
.infofooter i {
    margin-right: 14px;
    margin-top: 6px;
}

.infofooter {
    display: flex;
    justify-content: start;
    font-size: 14px;
}

.footer-bigarea h3{    color: #fff;}

.footer-bigarea {
    padding: 60px 0px;
    color: #fff;
    border-right: 1px solid #ffffff29;
    width: 78%;
}
.f-link {
    margin-top: 26px;
}


footer {
    background: #053f72;
    padding: 21px 0px;
    padding-bottom: 0px;
}
.footer-bigarea h3 {
    font-weight: 400;
    font-size: 43px;
}
.copyright .container {
    /* background: aliceblue; */
    border-top: 1px solid #ffffff2e;
    border-bottom: 1px solid #ffffff40;
    padding: 10px 0px;
}
.copyright_old .container{
    border-top: 1px solid #ffffff2e;
    border-bottom: 1px solid #ffffff40;
    padding: 10px 0px;
}
@media (max-width: 1200px){
    .copyright_old .container{
        padding: 30px 0px 10px 0px !important;
    }
}

.footer-bigarea h3 span {
    font-weight: bold;
}

.footer-bigarea a {
    display: inline-block;
    background: #fff;
    padding: 12px 30px;
    color: #000;
    border-radius: 5px;
    margin-top: 20px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}

.f-link ul {
    padding: 0px;
}

footer a{
    color: #fff;
    text-decoration: none;
}
ul.footermorelink {
    display: flex;
    justify-content: space-between;

    margin: 0px;

}

.f-link.onlymob {
    margin: 0px;
}

.copyright {
    background: #053f72;
    position: relative;
}
.copyright_old{
    background: #053f72;
    position: relative;
}
.poweredbytext{
    position: absolute;
    top: 20px;
    right: 20px;
}
p.copyy {
    margin: 0px !IMPORTANT;
    padding: 10px 0px;
}
footer a:hover{ color: #fff;}
.footer-bigarea a:hover{
    background: #919191;
    color: #fff;
}

    @media screen and (max-width:767px) {
.footer-bigarea {
    padding: 60px 0px;
    color: #fff;
    border-right: 0px;
    width: 100%;
}

ul.socilmedia li span {
    display: none;
}

ul.socilmedia {
    display: flex;
    justify-content: start;
    padding: 0px;
}
.footer-bigarea h3 {
    font-weight: 400;
    font-size: 34px;
}
.topfooter img {
    margin-left: 10px;
    margin-bottom: 16px;
}

}


.counterslider{ background-color: #0059A7;}


.sectitle p {
    font-weight: 400;
    font-size: 18px;
}

.slidercap h1 span {
    font-weight: bold;
}
.slidercap h1 {
    font-size: 60px;
}
ul.imgsmgroup {
    display: flex;
}
ul.imgsmgroup li {
    margin-left: -20px;
}
.exabox .countbox {

    width: 265px;
    border-right: 1px solid #ccc;
    position: relative;
}



.exabox .countbox:before {
    content: "";
    position: absolute;
    background: #4FF027;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    bottom: 6px;
    left: -18px;
}
.countbox h6 {
    font-size: 34px;
    color: #fff;
}
.countbox p{
    font-size: 16px;
    color: #fff;
}

.counterslider {
    background-color: #0059A7;
    padding: 30px 0px !important;
  }

  .spy {
    padding: 81px 0px;
  }

  .blog-heading h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 65px;
    color: #303030;

  }

  .blog-heading h3 span {
    font-weight: 300;
    font-size: 40px;
  }

  .blog-heading p {
    font-style: normal;
    font-weight: 500;
    font-size: 17.5087px;
    line-height: 31px;
    padding: 30px 0;
    color: #000;
  }
  .blog_heading_Updated {
    font-style: normal;
    font-weight: 500;
    font-size: 17.5087px;
    line-height: 32px;  
    padding: 13px 0 40px 0 !important;
    color: #000;
  }


  .try_theme_btn {
    background-color: #0059A7;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 13px 30px;
    border: 2px solid #0059A7;
    border-radius: 5px;
  }

  .sliderimg img {
    width: 82%;
  }  

  .socilmedia li span {
    display: none;
  }

  .loginform {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 12px;
    border-radius: 7px;
    margin: 70px 0px;
    background: #fff;
    border: 1px solid #ccc;
}

button.btn.try_theme_btn {
    display: block;
    width: 100%;
    text-align: center;
    background: #053f72;
    color: #fff;
    margin-bottom: 13px;
}

.loginform h3 {
    text-align: center;
    margin-bottom: 18px;
}



button.btn.try_theme_btn {
    display: block;
    width: 100%;
    text-align: center;
    background: #053f72;
    color: #fff;
    margin-bottom: 13px;
    padding: 8px 0px;
    font-weight: bold;
    font-size: 16px;
    width: 27%;
}

form.row.g-3.contacthomepage {
    background: #f7f7f7;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 21px;
    padding-top: 10px;
    border-radius: 10px;
    border: none;
    padding-top: 24px;
}


form.row.g-3.contacthomepage input.form-control {
    padding: 10px 13px;
    border: none;
}

form.row.g-3.contacthomepage textarea.form-control{    border: none;}

.contacthomepage-a input.form-control {
    border: 0px;
    padding: 15px 15px;
    border-bottom: 2px solid #053f7229;
    border-radius: 0px;
    margin-bottom: 13px;
}
.contacthomepage-a textarea.form-control.clear_string {
    border: 0px;
    border-bottom: 2px solid #053f7229;
    border-radius: 0px;
}

/* form.row.g-3.contacthomepage-a {
    border: 1px solid #ccc;
} */

form.row.g-3.contacthomepage-a {
    /* border: 3px solid #053f7200; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 37px;
    background: white;
    border-radius: 9px;
}

body.app.sidebar-mini {
    padding: 0px;
}

@media screen and (max-width:895px) {
    .poweredbytext{
        bottom: 0 !important;
        right: 0 !important;
        left: 0 !important;
        top: 37px !important;
    }

}