.qrcode-container {
  background-color: #888; /* Gray background color */
  padding: 20px;
  display: inline-block;
}

.qrCodeStyle{
  width: 128px !important;
  height: 128px !important;
}

@media(min-width: 430px) and (max-width: 590px){
  .qrCodeStyle{
    width: 90px !important;
    height: 90px !important;
  }
}


@media(min-width: 200px) and (max-width: 430px){
  .qrCodeStyle{
    width: 75px !important;
    height: 75px !important;
  }
}


/* ////////card 6 /////////// */


/* .qr_scanner_wrapper {
  background-image: url("../../../../public/Images/QRCODEcard1.png");
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  border-radius: 9px;
  border: 1px solid #8a826e;
} 

qrCodeStyle{
  margin: 'auto' !important;
  display: 'block' !important;
   border: '1px solid white' !important;
  border-radius: '10rem' !important
  ;
}




*/



/* ////////card 9 /////////// */


/* .qr_scanner_wrapper {
  background-color: #3936364d;
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  border-radius: 9px;
   
} */


/* ////////card 7 /////////// */


/* .qr_scanner_wrapper {
  background-color: #c0c2c570;
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  border-radius: 9px;
} */




/* ////////card 8 /////////// */

/* .qr_scanner_wrapper {
 background-color: #ccc9c9a3;
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  border-radius: 9px;
  border: 1px solid #3d3d3da3;
} */



/* ////////card 5 /////////// */

/* .qr_scanner_wrapper {
      background-size: 'cover';
      background-repeat: 'no-repeat';
      background-position: 'center';
      border-radius: 9px;
      background-color: #767676c2;
      border: 1px solid #1c1c1ca3;
}  */



/* ////////card 1 /////////// */


/* .qr_scanner_wrapper {
  background-color: white;
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  border-radius: 0px;

} */




/* .qrCodeStyle{
  margin: 'auto' !important;
  display: 'block' !important;
   border: '1px solid white' !important;
  border-radius: '10rem' !important
  ;
} */








/* //////////////////////////////////////////////////////// */


/* ////////card 1 /////////// */

/* 
.qr_scanner_wrapper {
  line-height: 0px !important;
} 


.Physical_card_one_backend {
  font-family: 'Patrick Hand', cursive !important;

  }

  .Physical_card_one{
  text-transform: uppercase !important;
} */




/* ////////card 2 /////////// */


/* .caption-front{
 line-height:60px;
}

.Physical_card_one{
   text-transform: capitalize;
}

.Physical_card_one_backend{
   text-transform: uppercase !important;
  font-family:'Open Sans' !important;    
} */



/* ////////card 3 /////////// */




/* .Physical_card_one{
   text-transform: capitalize !important;
   font-weight: 400 !important;
   line-height: 40px !important;
}

.Physical_card_one_backend{
  font-weight: 400 !important;
font-size: 14px !important;
} */





/* ////////card 5 /////////// */

/* .qr_scanner_wrapper{
  border: 2px solid #1c1c1ca3 !important;
  line-height: 0 !important;
}

.Physical_card_one{
  font-size: 34px !important;
} */



/* ////////card 6 /////////// */

/* .qr_scanner_wrapper {
 border-radius: 0 !important;
 line-height: 0 !important;
} 

.Physical_card_one{
 font-family: popins  font famly remove
text-align: end !important;
;
} */


/* ////////card 7 /////////// */

/* .qr_scanner_wrapper {
  border-radius: 0 !important;
  line-height: 0 !important;
 } 
 
 .Physical_card_one{
  font-family: popins  font famly remove 
 text-align: start !important;
 width: 100% !important;
 } */
 

/* ////////card 8 /////////// */


 /* .Physical_card_one{
   font-family: popins  font famly remove  
  text-align:start !important;
 } */



 /* ////////car 11 , 12 /////////// */

 /* 
 .qr_scanner_wrapper {
  background-image: url("../../../../public/Images/card11qrback.png");
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-position: 'center';
  line-height: 0 !important;
  border: 1px solid #8a826e;
} 


 .caption-front {
  position: absolute !important;
  top: 130px;
  left: 0;
  right: 0px;
  border: 0;
  line-height: 11px;
}

.Physical_card_one {
  background: linear-gradient(to top, #8f8e8e, #ebeaea);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: 700 !important;
   font-family: "Poppins", sans-serif !important; 
  text-transform: uppercase !important;
  font-size: 40px !important;
  text-align:center !important;
}


 .Physical_card_one_backend{
  color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
 } */