#Update_config_form{
    display: block;
}
#Update_config_FirstInput{
    width: 100%;
}

#Updatee_config_Button_Wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#Update_config_Button{
    background: linear-gradient(90deg,#01c3cc,#2a76e8);
    border-left: 0;
    border-radius: 12px;
    color: #fff;
    padding: 10px 25px;
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 14px;
}


#Currency_admin_button{
    width: 230px !important;
    color: white;
    font-weight: 500;
    font-size: 16px;
}

#create_new_currency_form{
    display: flex;
    flex-direction: column;
  
}

#create_new_currency_Inputfield{
    width: 100% !important;
}

#Create_newCurrency_Container{
    padding: 0 !important;
}
.currency_admin_rupees_para{
    font-size: 12px;
    font-weight: 600;
}

#Currency_Activate_Name{
    margin: 10px 0;
    font-size: 16px;
}

#Currency_Radio_wrapper{
    margin: 10px 0 15px 0; 
}
#Currency_radio_No{
    margin: 0 15px;
}



#Coupons_admin_createform_container{
    /* display: flex; */
    /* flex-direction: column; */
}
#Coupanadmin_Button_Wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
#Coupan_edit_Button{

    background: linear-gradient(90deg,#01c3cc,#2a76e8);
    border-left: 0;
    border-radius: 10px;
    color: #fff;
    /* padding: 10px 25px 10px 25px; */
    padding: 10px 30px;
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 16px;
    margin: 20px 0 0 0;

}


.add_config_buttonadminsetting{    
    font-size: 18px;
    width: 200px;
    height: 58px;
    padding: 15px 30px;
    /* margin-left: 20%; */
    text-transform: uppercase;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #01c3cc , #7d2ae8);
    border: 0;
    box-shadow: rgb(191 173 223 / 61%) 0px 8px 24px;
    border-radius: 50px;
}

.SettingUpdateConfig{
    width: 100% !important;
}





/* //////////////////////////////////////////////////////////////// */

.InstructionHeading{
    font-size: 26px;
    color: #14538C;
    font-weight: 600;
}

.DownloadSampleWrapper{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    
}

.DownloadSample{
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
}

.InstructionWrapper{
    margin: 20px 0;
   
}

.Instruction{
    padding:2px 0;
    color: #646464;
    font-size: 16px;
    list-style: disc;
}




/* ////////////////////////////////// */


.SelectCountrycode{
    height: 40px;
    border: 2px solid #7d7b7b3d;
    border-radius: 10px;
    font-size: 16px;
    outline: none;
    /* width: 150px; */
    width: 100%;
    /* margin-left: 20px; */
}